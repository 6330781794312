import React, { useState } from "react"

const ContactInfo = () => {
  const [test, setTest] = useState()
  // useEffect(() => {
  //   var href = window.location.hostname
  //   console.log(href)

  //   var index = href.indexOf(".")

  //   if (index == -1) return

  //   var domain = href.substring(0, index)

  //   console.log(domain)
  //   if (domain == "oguzhan") {
  //     setTest("oguzhan")
  //   } else if (domain == "mahmut") {
  //     setTest("mahmut")
  //   } else {
  //     setTest("hic biri")
  //   }
  // }, [])

  return (
    <div className="contact-info-area pt-100 pb-70">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-6">
            <div className="contact-info-box">
              <div className="back-icon">
                <i className="bx bx-map"></i>
              </div>
              <div className="icon">
                <i className="bx bx-map"></i>
              </div>
              <h3>Adresimiz</h3>
              <p>
                Kazlıçeşme Mah. 245.Sok No.5 Biruni Teknopark <br />
                Zeytinburnu/İstanbul
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="contact-info-box">
              <div className="back-icon">
                <i className="bx bx-phone-call"></i>
              </div>
              <div className="icon">
                <i className="bx bx-phone-call"></i>
              </div>
              <h3>Müşteri Hizmetleri</h3>
              <p>
                Telefon: <a href="tel:02126512020">0 (212) 651 20 20</a>
              </p>
              {/* <p>
                E-Posta: <a href="mailto:hello@rewy.com">hello@rewy.com</a>
              </p> */}
            </div>
          </div>
          <div className="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
            <div className="contact-info-box">
              <div className="back-icon">
                <i className="bx bx-time-five"></i>
              </div>
              <div className="icon">
                <i className="bx bx-time-five"></i>
              </div>
              <h3>Çalışma Saatleri</h3>
              <p>Haftanın 7 günü: 09:00 - 19:00</p>
              {/* <p>Sunday & Saturday: 10:30 - 22:00</p> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactInfo
