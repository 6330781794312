import React from "react"

import { Button, Modal } from "react-bootstrap"
/// show,onHide,isSuccess,message props required
/// extraComponent props optional
const ResultModal = props => {
  const message = props.message
  const extraComponent = props.extraComponent

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className={props.isSuccess ? "bg-success" : "bg-danger"}>
        <Modal.Title className="text-white">İşlem Sonucu</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {props.isSuccess ? (
          <div className="text-success">
            <i className="fa fa-check-circle mr-2" aria-hidden="true"></i>
            {message}
          </div>
        ) : (
          <div className="text-danger">
            <i className="fa fa-times-circle mr-2" aria-hidden="true"></i>
            {message}
          </div>
        )}

        {extraComponent != undefined ? (
          <div className="pt-4"> {extraComponent}</div>
        ) : (
          <></>
        )}
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-secondary" onClick={props.onHide}>
          Tamam
        </button>
      </Modal.Footer>
    </Modal>
  )
}

export default ResultModal
