import React, { useEffect } from "react"
import Footer from "../components/App/Footer"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from "../components/Common/PageBanner"
import ContactForm from "../components/Contact/ContactForm"
import ContactInfo from "../components/Contact/ContactInfo"
const Contact = () => {
  const getCallMeSrc = () => {
    var raw = "https://cdn.bitrix24.com.tr/b27663209/crm/form/loader_6.js"
    var suffix = "?" + ((Date.now() / 180000) | 0)

    return raw + suffix
  }

  const addScript = () => {
    const script = document.createElement("script")

    script.src = getCallMeSrc()
    script.setAttribute("data-b24-form", "inline/6/bwx5kt")
    script.setAttribute("data-skip-moving", "true")

    const bitrixDiv = document.getElementById("contact-form-bitrix")

    bitrixDiv.appendChild(script)
  }

  useEffect(() => {
    addScript()
  }, [])
  return (
    <Layout>
      <Navbar />
      <PageBanner
        pageTitle="İletişim"
        homePageText="Anasayfa"
        homePageUrl="/"
        activePageText="İletişim"
      />
      <ContactForm />
      <ContactInfo />

      <Footer />
    </Layout>
  )
}

export default Contact
