import React, { useState } from "react"

import contact from "../../assets/images/contact.png"
import ResultModal from "../App/ResultModal"
const ContactForm = () => {
  const successMessage = "Form başarıyla gönderilmiştir."

  const [isLoading, setIsLoading] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [show, setShow] = useState(false)
  const [message, setMessage] = useState(successMessage)
  return (
    <section className="contact-area pb-100">
      <ResultModal
        show={show}
        onHide={() => setShow(false)}
        message={message}
        isSuccess={isSuccess}
      />
      <div className="container">
        <div className="section-title">
          <h2>BİZİMLE İLETİŞİME GEÇİN</h2>
        </div>

        <div className="row">
          <div className="col-lg-5 col-md-11">
            <div className="contact-image">
              <img src={contact} alt="contact" />
            </div>
          </div>

          <div className="col-lg-6 col-md-12">
            <div id="contact-form-bitrix"></div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ContactForm
